import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './views/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './assets/css/custom.css';
import 'react-datepicker/dist/react-datepicker.css';
import PrivateRoute from './utils/PrivateRoute';

import Logs from './views/support/logs';
import GroupBalance from './views/support/balances';
import LoanRequests from './views/support/loans/requests';

import Groups from './views/staging/groups/index';
import Dashboard from './views/staging/dashboard/index';
import GroupDetails from './views/staging/groups/GroupDetails';
import Migration from './views/staging/migration';
import Register from './views/staging/register';
import ChangePassword from './views/ChangePassword';
import { socket, socketContext } from './utils/socketContext';

toast.configure();

const Routes = () => (
  <React.StrictMode>
    <Suspense fallback="loading">
      <BrowserRouter>
        <Switch>
          <socketContext.Provider value={socket}>
            <Route exact path="/login" component={Login} />
            <Route exact path="/" component={Login} />
            <Route exact path="/change-password" component={ChangePassword} />

            <PrivateRoute exact path="/staging/groups" component={Groups} />
            <PrivateRoute exact path="/staging/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/staging/groups/:group_id" component={GroupDetails} />
            <PrivateRoute exact path="/staging/migration" component={Migration} />
            <Route exact path="/staging/register" component={Register} />

            <PrivateRoute exact path="/support/logs" component={Logs} />
            <PrivateRoute exact path="/support/balances" component={GroupBalance} />
            <PrivateRoute exact path="/support/balances/:group_id" component={GroupBalance} />
            <PrivateRoute exact path="/support/loan-requests" component={LoanRequests} />
            <PrivateRoute exact path="/support/loan-requests/:group_id" component={LoanRequests} />
          </socketContext.Provider>
        </Switch>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);

export default Routes;
