import { combineReducers } from 'redux';
import auth from './authReducer';
import groupsStagging from './groupsStaggingReducer';
import membersStagging from './membersStaggingReducer';
import reasonsStagging from './reasonsStaggingReducer';
import locationsStagging from './locationsStaggingReducer';
import commentsStagging from './commentsStaggingReducer';
import approvalsStagging from './approvalsStaggingReducer';
import adminsStagging from './adminsStaggingReducer';
import dashboardStagging from './dashboardStaggingReducer';
import migrations from './migrationsStaggingReducer';
import logs from './logsReducer';
import support from './supportReducer';
import groupsProductionReducer from './groupsProductionReducer';
import organizations from './organizationsReducer';

const rootReducer = combineReducers({
  auth,
  groupsStagging,
  membersStagging,
  reasonsStagging,
  locationsStagging,
  commentsStagging,
  approvalsStagging,
  adminsStagging,
  dashboardStagging,
  migrations,
  logs,
  support,
  productionGroups: groupsProductionReducer,
  organizations,
});

export default rootReducer;
