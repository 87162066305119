import React from 'react';
import Container from '../../../components/container';
import Group from './components/group';

const GroupDetails = () => (
  <Container pageTitle="Group Details">
    <Group />
  </Container>
);
export default GroupDetails;
