import React, { useEffect } from 'react';
import { Form, Input, Button, Card, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createAccount } from '../../../redux/actions/authActions';
import Container from '../../../components/container';
import { mainAction } from '../../../redux/actions/mainAction';
import { GET_ORGANIZATIONS } from '../../../redux/types';

const Migration = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { isLoading } = useSelector(({ auth }) => auth);
  const orgs = useSelector(({ organizations }) => organizations?.organizations?.data);

  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_PROD_BASE_URL
      : process.env.REACT_APP_DEV_BASE_URL;

  const onFinish = (values) => dispatch(createAccount(values));

  useEffect(() => {
    dispatch(mainAction('get', baseUrl, '/organizations', GET_ORGANIZATIONS));
  }, []);

  return (
    <Container pageTitle="Register Staff">
      <Card className="auth-card ml-3" hoverable>
        <Form layout="vertical" onFinish={onFinish} hideRequiredMark>
          {(fields) => (
            <>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter your name' }]}
              >
                <Input placeholder="Enter your Name" />
              </Form.Item>

              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please enter your username' }]}
              >
                <Input placeholder="Enter your username" />
              </Form.Item>

              <Form.Item
                label="Organization"
                name="org_id"
                rules={[{ required: true, message: 'Please enter your organization' }]}
              >
                <Select
                  showSearch
                  placeholder="Select an organization"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {orgs?.map(({ org_id, org_name }, index) => (
                    <Option key={index?.toString()} value={org_id}>
                      {org_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Cluster"
                name="cluster_id"
                rules={[{ required: true, message: 'Please enter your cluster' }]}
              >
                <Select
                  showSearch
                  placeholder="Select a cluster"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {orgs
                    ?.find(({ org_id }) => org_id === fields?.org_id)
                    ?.clusters?.map((value, index) => (
                      <Option key={index?.toString()} value={value?.cluster_id?.toString()}>
                        {value?.cluster_name?.toUpperCase()}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Role"
                name="access_level"
                rules={[{ required: true, message: 'Please enter your Role' }]}
              >
                <Select
                  showSearch
                  placeholder="Select a Role"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="3">Supervisor</Option>
                  <Option value="2">Staff</Option>
                  <Option value="1">Admin</Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  loading={isLoading}
                  disabled={isLoading}
                  htmlType="submit"
                  block
                >
                  Register
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </Card>
    </Container>
  );
};
export default Migration;
