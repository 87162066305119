import React, { useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import StatsHeader from './StatsHeader';
import Charts from './Charts';
import { getDashboardData } from '../../../../../redux/actions/dashboardStaggingActions';

const Statistics = ({ getData, data: { values: data, isLoading } }) => {
  useEffect(() => {
    getData();
  }, []);
  return (
    <Container fluid>
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <StatsHeader data={data} />
          <Charts data={data} />
        </>
      )}
    </Container>
  );
};

const mapState = ({ dashboardStagging }) => ({ data: dashboardStagging });
export default connect(mapState, { getData: getDashboardData })(Statistics);