/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Steps, Button, message } from 'antd';
import { Container } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { migrateAction } from '../../../../../redux/actions/migrateStaggingAction';
import * as types from '../../../../../redux/types';
import { mainAction } from '../../../../../redux/actions/mainAction';

const { Step } = Steps;

const steps = [
  {
    title: 'Groups',
    content: (
      <Steps progressDot current={5} direction="vertical">
        <Step title="Fetch" description="Fetch new Groups in staging Database" />
        <Step title="Create" description="Migrate staging groups to the production Database" />
        <Step
          title="Update"
          description="Update staging groups with new Group Codes and Production Group IDs"
        />
      </Steps>
    ),
  },
  {
    title: 'Wallets',
    content: (
      <Steps progressDot current={5} direction="vertical">
        <Step title="Fetch" description="Fetch migrated Groups" />
        <Step
          title="Create"
          description="Create Product Wallets of the migrated groups to the production Database"
        />
      </Steps>
    ),
  },
  {
    title: 'Fines & Social fund',
    content: (
      <Steps progressDot current={5} direction="vertical">
        <Step title="Fetch" description="Fetch reasons and fines in the staging database" />
        <Step title="Fines" description="Create Fines to the production Database" />
        <Step
          title="Socialfund"
          description="Create Socialfund reasons to the production Database"
        />
      </Steps>
    ),
  },
  {
    title: 'Members',
    content: (
      <Steps progressDot current={5} direction="vertical">
        <Step title="Fetch" description="Fetch staging members" />
        <Step title="Settings" description="Create members and their settings" />
        <Step title="Accounts" description="Create members Accounts" />
      </Steps>
    ),
  },
  {
    title: 'Admins',
    content: (
      <Steps progressDot current={5} direction="vertical">
        <Step title="Fetch" description="Fetch staging admins" />
        <Step title="Create" description="Migrate admins to production database" />
      </Steps>
    ),
  },
];

const MigrationSteps = ({ migrate, migrations }) => {
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();
  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_PROD_BASE_URL
      : process.env.REACT_APP_DEV_BASE_URL;

  const next = () => {
    const action =
      current === 0
        ? { endpoint: '/groups', actionType: types.MIGRATE_GROUPS }
        : current === 1
        ? { endpoint: '/wallets', actionType: types.MIGRATE_WALLETS }
        : current === 2
        ? { endpoint: '/reasons', actionType: types.MIGRATE_REASONS }
        : current === 3
        ? { endpoint: '/members', actionType: types.MIGRATE_MEMBERS }
        : current === 4
        ? { endpoint: '/admins', actionType: types.MIGRATE_ADMINS }
        : { endpoint: '/', actionType: types.COMPLETE_MIGRATIONS };

    migrate('get', action.endpoint, action.actionType);
    if (current === 4) {
      console.log(current);
      dispatch(
        mainAction('get', baseUrl, '/groups/migrate?stage=finish', types.COMPLETE_MIGRATIONS),
      );
      return message.success('Migration completed');
    }
    setCurrent(current + 1);
  };

  return (
    <>
      <Container fluid>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="m-4">{steps[current].content}</div>
        <div className="mt-3">
          {current < steps.length - 1 && (
            <Button type="primary" loading={migrations.isLoading} onClick={() => next()}>
              {current === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Finish
            </Button>
          )}
        </div>
      </Container>
    </>
  );
};
const mapState = ({ migrations }) => ({ migrations });
export default connect(mapState, { migrate: migrateAction })(MigrationSteps);
