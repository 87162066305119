/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import BarChartComp from '../charts/BarChartComp';
import PieChartComp from '../charts/PieChartComp';

const Charts = ({ data: { clusters, male, female, sim, noSim } }) => {
  const genderData = [
    { name: 'Male', value: male },
    { name: 'Female', value: female },
  ];

  const phoneNumberData = [
    { name: 'SIM', value: sim },
    { name: 'No SIM ', value: noSim },
  ];

  return (
    <Row className="mt-3">
      <Col>
        <Row>
          <Col lg="12">
            <Card className="mb-5">
              <Card.Body>
                <Row>
                  <Col>
                    <h3>Gender</h3>
                    <PieChartComp data={genderData} />
                  </Col>
                  <Col>
                    <h3>SIM cards</h3>
                    <PieChartComp data={phoneNumberData} />
                  </Col>
                  <Col>
                    <h3>Clusters</h3>
                    <BarChartComp data={clusters} name="cluster" value="groups" />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Charts;
