// ACCOUNT
export const CREATE_ACCOUNT_START = 'CREATE_ACCOUNT_START';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

// GROUPS
export const GET_GROUPS_START = 'GET_GROUPS_START';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_ERROR = 'GET_GROUPS_ERROR';

export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_START = 'CREATE_GROUP_START';
export const CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';

export const GET_ONE_GROUP_START = 'GET_ONE_GROUP_START';
export const GET_ONE_GROUP_SUCCESS = 'GET_ONE_GROUP_SUCCESS';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';

// MEMBERS
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_ERROR = 'CREATE_MEMBER_ERROR';
export const CREATE_MEMBER_START = 'CREATE_MEMBER_START';
export const UPLOAD_MEMBERS_SUCCESS = 'UPLOAD_MEMBERS_SUCCESS';
export const GET_MEMBERS_START = 'GET_MEMBERS_START';
export const GET_MEMBERS_ERROR = 'GET_MEMBERS_ERROR';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';

export const GET_ONE_MEMBER_SUCCESS = 'GET_ONE_MEMBER_SUCCESS';

export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';

export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';

// REASONS
export const GET_REASONS_START = 'GET_REASONS_START';
export const GET_REASONS_SUCCESS = 'GET_REASONS_SUCCESS';
export const GET_REASONS_ERROR = 'GET_REASONS_ERROR';

export const CREATE_REASON_SUCCESS = 'CREATE_REASON_SUCCESS';
export const CREATE_REASON_START = 'CREATE_REASON_START';
export const CREATE_REASON_ERROR = 'CREATE_REASON_ERROR';
export const DELETE_REASON_SUCCESS = 'DELETE_REASON_SUCCESS';

export const GET_ONE_REASON_START = 'GET_ONE_REASON_START';
export const GET_ONE_REASON_SUCCESS = 'GET_ONE_REASON_SUCCESS';
export const UPDATE_REASON_SUCCESS = 'UPDATE_REASON_SUCCESS';

// LOCATIONS
export const FIND_LOCATION_START = 'FIND_LOCATION_START';
export const FIND_PROVINCE_SUCCESS = 'FIND_PROVINCE_SUCCESS';
export const FIND_DISTRICT_SUCCESS = 'FIND_DISTRICT_SUCCESS';
export const FIND_SECTOR_SUCCESS = 'FIND_SECTOR_SUCCESS';
export const FIND_CELL_SUCCESS = 'FIND_CELL_SUCCESS';
export const FIND_VILLAGE_SUCCESS = 'FIND_VILLAGE_SUCCESS';
export const FIND_LOCATION_ERROR = 'FIND_LOCATION_ERROR';

// COMMENTS
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_ERROR = 'GET_COMMENTS_ERROR';
export const GET_COMMENTS_START = 'GET_COMMENTS_START';
export const CREATE_COMMENT_START = 'CREATE_COMMENT_START';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_ERROR = 'CREATE_COMMENT_ERROR';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';

// APPROVALS
export const GET_APPROVALS_SUCCESS = 'GET_APPROVALS_SUCCESS';
export const GET_APPROVALS_ERROR = 'GET_APPROVALS_ERROR';
export const GET_APPROVALS_START = 'GET_APPROVALS_START';
export const CREATE_APPROVAL_START = 'CREATE_APPROVAL_START';
export const CREATE_APPROVAL_ERROR = 'CREATE_APPROVAL_ERROR';
export const UPDATE_APPROVAL_SUCCESS = 'UPDATE_APPROVAL_SUCCESS';
export const CREATE_APPROVAL_SUCCESS = 'CREATE_APPROVAL_SUCCESS';

// ADMINS
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMINS_ERROR = 'GET_ADMINS_ERROR';
export const GET_ADMINS_START = 'GET_ADMINS_START';
export const CREATE_ADMIN_START = 'CREATE_ADMIN_START';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_ERROR = 'CREATE_ADMIN_ERROR';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPLOAD_ADMINS_SUCCESS = 'UPLOAD_ADMINS_SUCCESS';

// DASHBOARD
export const GET_DASHBOARD_STARTS = 'GET_DASHBOARD_STARTS';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const MIGRATION_START = 'MIGRATION_START';
export const MIGRATE_GROUPS = 'MIGRATE_GROUPS';
export const MIGRATE_WALLETS = 'MIGRATE_WALLETS';
export const MIGRATE_MEMBERS = 'MIGRATE_MEMBERS';
export const MIGRATE_REASONS = 'MIGRATE_REASONS';
export const MIGRATE_ADMINS = 'MIGRATE_ADMINS';
export const COMPLETE_MIGRATIONS = 'COMPLETE_MIGRATIONS';
export const MIGRATION_FAILED = 'MIGRATION_FAILED';

export const FETCH_LOGS = 'FETCH_LOGS';
export const NEW_ACTION_START = 'NEW_ACTION_START';
export const FETCH_USER_LOG_FILE = 'FETCH_USER_LOG_FILE';
export const FETCH_SUPPORT_GROUPS = 'FETCH_SUPPORT_GROUPS';
export const FETCH_SUPPORT_GROUP_BALANCES = 'FETCH_SUPPORT_GROUP_BALANCES';
export const UPDATE_GROUP_BALANCES = 'UPDATE_GROUP_BALANCES';
export const FETCH_GROUP_LOAN_REQUESTS = 'FETCH_GROUP_LOAN_REQUESTS';

// PRODUCTION

export const GET_PRODUCTION_GROUPS = 'GET_PRODUCTION_GROUPS';

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
