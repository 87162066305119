import { toast } from 'react-toastify';
import HttpRequest from '../../services/HttpRequest';
import creator from './creator';
import {
  CREATE_ACCOUNT_START,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_ERROR,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
} from '../types';
import AuthToken from '../../utils/authToken';

export const createAccount = (data) => async (dispatch) => {
  try {
    dispatch(creator(CREATE_ACCOUNT_START, true));
    const res = await HttpRequest.post('/auth/register', data);
    toast.success(res.message);
    dispatch(creator(CREATE_ACCOUNT_SUCCESS, res.data));
  } catch (e) {
    if (e.response && e.response.data) {
      dispatch(creator(CREATE_ACCOUNT_ERROR, e.response.data.error));
      return toast.error(e.response.data.error);
    }
  }
};

export const login = (data) => async (dispatch) => {
  try {
    const loggedout = localStorage.getItem('loggedout');
    dispatch(creator(LOGIN_START, true));
    const res = await HttpRequest.post('/auth/login', data);
    AuthToken.setToken(res.data);
    dispatch(creator(LOGIN_SUCCESS, res.data));
    if (loggedout) {
      localStorage.removeItem('loggedout');
      return window.location.assign(JSON.parse(loggedout).path);
    }
    localStorage.removeItem('loggedout');
    window.location.assign('/staging/dashboard');
  } catch (e) {
    const error = e?.response?.data?.error || e?.message;
    dispatch(creator(LOGIN_ERROR, error));
    return toast.error(error);
  }
};
