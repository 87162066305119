/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import { Drawer, Form, Button, Col, Row, Upload, Progress } from 'antd';
import { useDispatch } from 'react-redux';
import { InboxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { upload as uploadMembers } from '../../../../../redux/actions/membersStaggingActions';
import { upload as uploadGroup } from '../../../../../redux/actions/groupsStaggingActions';
import { socketContext } from '../../../../../utils/socketContext';

const { Dragger } = Upload;

const NewUpload = ({ visible, closeDrawer, uploadType }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { group_id } = useParams();
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState({
    fileList: [],
    total: 0,
    processed: 0,
    status: 'stop',
  });
  let processedFilesPercent = 0;
  processedFilesPercent = progress.processed / progress.total;
  processedFilesPercent = progress.processed === 0 ? 0 : processedFilesPercent * 100;

  const onClose = () => closeDrawer();
  const onReset = () => {
    form.resetFields();
    setFileList([]);
    setProgress({
      fileList: [],
      total: 0,
      processed: 0,
      status: 'stop',
    });
  };

  const socket = useContext(socketContext);

  socket.on('progress', (data) => {
    setProgress(data);
    setFileList(data?.fileList);
  });

  socket.on('connect', () => {
    console.log(`socket io is connected at ${new Date()}`);
  });

  const onFinish = () => {
    const formData = new FormData();
    if (uploadType === 'members') {
      formData.append('members_file', fileList[0]);
      return dispatch(uploadMembers(formData, group_id));
    }
    if (uploadType === 'group') {
      fileList.map(({ originFileObj }) => formData.append('group_files', originFileObj));
      return dispatch(uploadGroup(formData));
    }
  };

  const onChange = (values) => {
    setFileList(values?.fileList);
  };

  return (
    <>
      <Drawer
        title="Upload file(s)"
        width={720}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={onReset} style={{ marginRight: 8 }}>
              Reset
            </Button>
            <Button
              type="primary"
              loading={progress?.status === 'processing'}
              disabled={progress?.status === 'completed'}
              key="submit"
              form="uploadForm"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          id="uploadForm"
          layout="vertical"
          initialValues={{}}
          onFinish={onFinish}
          hideRequiredMark
        >
          <Row gutter={16}>
            {progress?.status === 'processing' && (
              <Progress
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
                status="active"
                percent={processedFilesPercent?.toPrecision(3)}
              />
            )}
            <Col span={24}>
              <Form.Item
                name="files"
                rules={[{ required: true, message: 'Atleast one file is required' }]}
              >
                <Dragger
                  multiple={uploadType === 'group'}
                  beforeUpload={() => false}
                  customRequest={() => false}
                  onChange={onChange}
                  fileList={fileList}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drop file(s) to this area to upload</p>
                  <p className="ant-upload-hint">Only XLSX file(s) are allowed</p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
NewUpload.propTypes = {
  visible: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
  uploadType: PropTypes.string.isRequired,
};
NewUpload.defaultProps = {
  visible: false,
};
export default NewUpload;
