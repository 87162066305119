import React from 'react';
import Container from '../../../components/container';
import GroupsTable from './components/GroupsTable';

const Groups = () => (
  <Container pageTitle="Groups">
    <GroupsTable />
  </Container>
);
export default Groups;
